<!--
* 创建人：罗兴
* 日 期：
* 描 述：优秀学生干部申请配置
-->
<!--view 模板-->
<template>
  <div class="container">
    <el-scrollbar :style="{ height: windowHeight + 'px' }">
      <div class="c_box">
        <div class="top-bar">
          <span class="top-title">优秀学生干部申请配置</span>
        </div>
        <div class="pzbox">
          <div class="pzitem">
            <el-checkbox v-model="forminfo.KNS" :true-label="1" :false-label="0"
              >必须是家庭经济认证困难学生</el-checkbox
            >
          </div>
          <div class="pzitem">
            <el-checkbox
              v-model="forminfo.XNCF"
              :true-label="1"
              :false-label="0"
              >本学年无处分</el-checkbox
            >
          </div>
          <div class="pzitem">
            <el-checkbox
              v-model="forminfo.ZXCF"
              :true-label="1"
              :false-label="0"
              >在校期间无处分</el-checkbox
            >
          </div>
          <div class="pzitem">
            <el-checkbox
              v-model="forminfo.XNGK"
              :true-label="1"
              :false-label="0"
              >本学年无挂科</el-checkbox
            >
          </div>
          <div class="pzitem">
            <el-checkbox
              v-model="forminfo.ZXGK"
              :true-label="1"
              :false-label="0"
              >在校期间无挂科</el-checkbox
            >
          </div>
          <div class="pzitem">
            <el-checkbox v-model="forminfo.DY" :true-label="1" :false-label="0"
              >当前年度综和测评德育成绩大于等于</el-checkbox
            >
            <el-input
              v-model="forminfo.DYCJ"
              type="number"
              size="mini"
              style="margin-left: 5px; width: 100px"
            ></el-input>
          </div>
          <div class="pzitem">
            <el-checkbox v-model="forminfo.ZY" :true-label="1" :false-label="0"
              >当前年度综和测评智育成绩大于等于</el-checkbox
            >
            <el-input
              v-model="forminfo.ZYCJ"
              type="number"
              size="mini"
              style="margin-left: 5px; width: 100px"
            ></el-input>
          </div>
          <div class="pzitem">
            <el-checkbox v-model="forminfo.TY" :true-label="1" :false-label="0"
              >当前年度综和测评体育成绩大于等于</el-checkbox
            >
            <el-input
              v-model="forminfo.TYCJ"
              type="number"
              size="mini"
              style="margin-left: 5px; width: 100px"
            ></el-input>
          </div>
          <div class="pzitem">
            <el-checkbox v-model="forminfo.MY" :true-label="1" :false-label="0"
              >当前年度综和测评美育成绩大于等于</el-checkbox
            >
            <el-input
              v-model="forminfo.MYCJ"
              type="number"
              size="mini"
              style="margin-left: 5px; width: 100px"
            ></el-input>
          </div>
          <div class="pzitem">
            <el-checkbox v-model="forminfo.LD" :true-label="1" :false-label="0"
              >当前年度综和测评劳动成绩大于等于</el-checkbox
            >
            <el-input
              v-model="forminfo.LDCJ"
              type="number"
              size="mini"
              style="margin-left: 5px; width: 100px"
            ></el-input>
          </div>
          <div class="pzitem">
            <el-checkbox v-model="forminfo.NL" :true-label="1" :false-label="0"
              >当前年度综和测评能力成绩大于等于</el-checkbox
            >
            <el-input
              v-model="forminfo.NLCJ"
              type="number"
              size="mini"
              style="margin-left: 5px; width: 100px"
            ></el-input>
          </div>
          <div class="pzitem">
            <el-checkbox
              v-model="forminfo.ZYZHCP"
              :true-label="1"
              :false-label="0"
              >当前年度综测综合成绩专业排名小于等于</el-checkbox
            >
            <el-input
              v-model="forminfo.ZYZHCPPM"
              type="number"
              size="mini"
              style="margin-left: 5px; width: 100px"
            ></el-input
            >%
          </div>
          <div class="pzitem">
            <el-checkbox
              v-model="forminfo.BJZHCP"
              :true-label="1"
              :false-label="0"
              >当前年度综测综合成绩班级排名小于等于</el-checkbox
            >
            <el-input
              v-model="forminfo.BJZHCPPM"
              type="number"
              size="mini"
              style="margin-left: 5px; width: 100px"
            ></el-input
            >%
          </div>
          <el-button type="primary" @click="save" style="margin-top: 10px"
            >提交保存</el-button
          >
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getDJJXJSQPZInfo, DJJXJSQPZSave } from '../../api/jxj'
export default {
  data() {
    return {
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      forminfo: {
        ID: '',
        JXLB: 4,
        KNS: 0,
        XNCF: 0,
        ZXCF: 0,
        XNGK: 0,
        ZXGK: 0,
        DY: 0,
        DYCJ: null,
        ZY: 0,
        ZYCJ: null,
        TY: 0,
        TYCJ: null,
        MY: 0,
        MYCJ: null,
        LD: 0,
        LDCJ: null,
        NL: 0,
        NLCJ: null,
        ZYZHCP: 0,
        ZYZHCPPM: null,
        BJZHCP: 0,
        BJZHCPPM: null,
      },
    }
  },
  created() {
    getDJJXJSQPZInfo({ lx: 4 }).then((res) => {
      if (res.code === 200 && res.data) {
        this.forminfo.ID = res.data.id
        this.forminfo.JXLB = res.data.jxlb
        this.forminfo.KNS = res.data.kns
        this.forminfo.XNCF = res.data.xncf
        this.forminfo.ZXCF = res.data.zxcf
        this.forminfo.XNGK = res.data.xngk
        this.forminfo.ZXGK = res.data.zxgk
        this.forminfo.DY = res.data.dy
        this.forminfo.DYCJ = res.data.dycj
        this.forminfo.ZY = res.data.zy
        this.forminfo.ZYCJ = res.data.zycj
        this.forminfo.TY = res.data.ty
        this.forminfo.TYCJ = res.data.tycj
        this.forminfo.MY = res.data.my
        this.forminfo.MYCJ = res.data.mycj
        this.forminfo.LD = res.data.ld
        this.forminfo.LDCJ = res.data.ldcj
        this.forminfo.NL = res.data.nl
        this.forminfo.NLCJ = res.data.nlcj
        this.forminfo.ZYZHCP = res.data.zyzhcp
        this.forminfo.ZYZHCPPM = res.data.zyzhcppm
        this.forminfo.BJZHCP = res.data.bjzhcp
        this.forminfo.BJZHCPPM = res.data.bjzhcppm
      }
    })
  },
  methods: {
    save() {
      if (this.forminfo.DY === 1 && this.forminfo.DYCJ == null) {
        this.$message.error('请完善内容')
        return false
      }
      if (this.forminfo.ZY === 1 && this.forminfo.ZYCJ == null) {
        this.$message.error('请完善内容')
        return false
      }
      if (this.forminfo.TY === 1 && this.forminfo.TYCJ == null) {
        this.$message.error('请完善内容')
        return false
      }
      if (this.forminfo.MY === 1 && this.forminfo.MYCJ == null) {
        this.$message.error('请完善内容')
        return false
      }
      if (this.forminfo.LD === 1 && this.forminfo.LDCJ == null) {
        this.$message.error('请完善内容')
        return false
      }
      if (this.forminfo.NL === 1 && this.forminfo.NLCJ) {
        this.$message.error('请完善内容')
        return false
      }
      DJJXJSQPZSave(this.forminfo).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.c_box {
  margin: 0 auto;
  width: 800px;
  padding: 50px 0 100px 0;
}
.top-bar {
  border-bottom: 1px solid #dfe2e8;
  line-height: 32px;
  padding-bottom: 5px;
}
.top-title {
  font-weight: 700;
  color: #303030;
  font-size: 16px;
}
.pzbox {
  margin-top: 10px;
}
.pzitem {
  margin-top: 10px;
}
.pzitem {
  display: flex;
  align-items: center;
}
</style>
